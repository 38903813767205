<!--
 * @Author: 小宝 zksukuo@yunkangda.com
 * @Date: 2023-12-21 08:24:19
 * @LastEditors: 小宝 zksukuo@yunkangda.com
 * @LastEditTime: 2023-12-21 10:33:36
 * @FilePath: \visions\src\views\zhyl\smartPublicGuard\healthTestRun\healthReport\tab2\test.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="test">
    <div class="test-box">
      <div class="test-head">
        <span>体检人数</span>
        <span class="el-icon-close test-close" @click="close"></span>
      </div>
      <div class="test-search">
        <div class="test-item">
          <div class="key">
            <span>体检日期</span>
          </div>
          <el-date-picker v-model="value1" type="date" placeholder="体检日期">
          </el-date-picker>
        </div>
        <div class="test-item">
          <div class="key">
            <span>姓名</span>
          </div>
          <el-input v-model="input" placeholder="姓名"></el-input>
        </div>
        <div class="test-item">
          <div class="key">
            <span>身份证号</span>
          </div>
          <el-input v-model="input" placeholder="身份证号"></el-input>
        </div>
        <div class="test-item">
          <div class="key">
            <span>人群分类</span>
          </div>
          <el-select v-model="value" placeholder="人群分类">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="test-item">
          <el-button type="primary">
            <span class="el-icon-search"></span>
            <span>搜索</span>
          </el-button>
          <el-button>
            <span class="el-icon-delete"></span>
            <span>清空</span>
          </el-button>
        </div>
      </div>
      <div class="test-table">
        <el-table :data="tableData" height="100%" border>
          <el-table-column prop="id" label="序号" :width="flexWidth('id', tableData, '序号')" fixed="left">
          </el-table-column>
          <el-table-column prop="name" label="体检日期" :width="flexWidth('name', tableData, '体检日期')"></el-table-column>
          <el-table-column prop="name" label="姓名" :width="flexWidth('name', tableData, '姓名')"></el-table-column>
          <el-table-column prop="age" label="年龄" :width="flexWidth('name', tableData, '年龄')"></el-table-column>
          <el-table-column prop="name" label="性别" :width="flexWidth('name', tableData, '性别')">
            <template>
              <el-tag>男</el-tag>
              <!-- <el-tag type="danger">女</el-tag> -->
            </template>
          </el-table-column>
          <el-table-column prop="sfzh" label="身份证号" :width="flexWidth('name', tableData, '身份证号')"></el-table-column>
          <el-table-column prop="name" label="人群分类" :width="flexWidth('name', tableData, '人群分类')"></el-table-column>
          <el-table-column prop="name" label="生化" :width="flexWidth('name', tableData, '生化')"></el-table-column>
          <el-table-column label="血常规" :width="flexWidth('name', tableData, '血常规')">
            <el-tag>已同步</el-tag>
            <!-- <el-tag type="danger">未同步</el-tag> -->
          </el-table-column>
          <el-table-column label="尿常规" :width="flexWidth('name', tableData, '尿常规')">
            <el-tag>已同步</el-tag>
            <!-- <el-tag type="danger">未同步</el-tag> -->
          </el-table-column>
          <el-table-column label="心电图" :width="flexWidth('name', tableData, '心电图')">
            <el-tag>已同步</el-tag>
            <!-- <el-tag type="danger">未同步</el-tag> -->
          </el-table-column>
          <el-table-column label="B超" :width="flexWidth('name', tableData, 'B超')">
            <el-tag>已同步</el-tag>
            <!-- <el-tag type="danger">未同步</el-tag> -->
          </el-table-column>
          <el-table-column label="上传状态" :width="flexWidth('name', tableData, '上传状态')"></el-table-column>
          <el-table-column label="同步状态" :width="flexWidth('name', tableData, '同步状态')"></el-table-column>
          <el-table-column label="平台状态" :width="flexWidth('name', tableData, '平台状态')"></el-table-column>
          <el-table-column label="操作" width="280" fixed="right">
            <!-- slot-scope="scope" -->
            <template>
              <div class="flex">
                <el-button type="primary" size="small">编辑</el-button>
                <el-button type="primary" size="small">同步</el-button>
                <el-button type="primary" size="small">报告预览</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="test-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="query1.pageNum" background :page-size="query1.pageSize" layout="total, prev, pager, next, jumper"
          :total="query1.total" hide-on-single-page>
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      input: '',
      value1: '',
      options: [],
      value: '',
      tableData: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  mounted () {
    this.getList()
  },
  methods: {
    // el-table自适应宽度
    flexWidth (prop, tableData, title, num = 0) {
      if (tableData.length === 0) { // 表格没数据不做处理
        return title.length * 30 + 'px'
      }
      let flexWidth = 0// 初始化表格列宽
      let columnContent = ''// 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if ((prop === '') && title) { // 标题长内容少的，取标题的值,
        columnContent = title
      } else { // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const nowTemp = tableData[i][prop] + ''
          const maxTemp = tableData[index][prop] + ''
          const nowTempW = context.measureText(nowTemp).width
          const maxTempW = context.measureText(maxTemp).width
          if (nowTempW > maxTempW) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const columnW = context.measureText(columnContent).width
        const titleW = context.measureText(title).width
        if (columnW < titleW) {
          columnContent = title || '留七个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 60 + num
      return flexWidth + 'px'
    },
    async getList () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97904037
      try {
        this.loading = true
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start,
          length: '10',
          ssjgbh: this.loginInfo.jgbh,
          xzjdmcid: '',
          cwhmcid: '',
          flag: '',
          sfzh: this.sfzh
        }
        const { data: res } = await this.$http.get('/ykdjktjbs', { params: obj, headers: { token: this.loginInfo.token } })
        console.log(res)
        this.loading = false
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.tableData = res.data.map(item => {
          return {
            ...item,
            ...{ showPopover: false }
          }
        })
        this.query1.total = res.recordsTotal
      } catch (error) {
        this.loading = false
        console.log('请求失败', error)
      }
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList()
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.test {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .6);

  .test-box {
    width: 80%;
    height: 90%;
    padding: 20px;
    background-color: #fff;
    box-sizing: border-box;

    .test-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 40px;
      font-size: 18px;

      .test-close {
        font-size: 24px;
        cursor: pointer;
      }
    }

    .test-search {
      display: flex;
      align-items: center;
      align-content: space-evenly;
      width: 100%;
      height: 80px;

      .test-item {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        margin-right: 20px;

        .key {
          margin-right: 10px;
          white-space: nowrap;
          color: #000;
          font-size: 16px;
        }
      }
    }

    .test-table {
      width: 100%;
      height: calc(100% - 200px);

      /deep/.el-table .cell {
        text-align: center;
        color: #000;
        font-size: 16px;
      }

      .flex {
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        font-size: 16px;

        span {
          cursor: pointer;
        }

        /deep/.el-button--small {
          font-size: 14px;
        }

        /deep/.el-button+.el-button {
          margin-left: 0;
        }
      }

      .green {
        color: #00BB7E;
      }

      .yellow {
        color: #FF8014;
      }

      .red {
        color: #FF0000;
      }

      .purple {
        color: #073F73;
        cursor: pointer;
      }

      .gray {
        color: #D2D2D2;
        cursor: pointer;
      }
    }

    .test-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 80px;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #000;

        .el-input__inner {
          color: #000;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }
  }
}
</style>
